
















import { Component, Vue } from 'vue-property-decorator'
import { namespace, Action } from 'vuex-class'
import { Route } from 'vue-router'
// import 'vue-router/types/vue'
const auth = namespace('auth')
@Component({
	layout: 'plain'
})
export default class Login extends Vue {

	private form: {email: string, password: string} = {email: '', password: ''}

	submiting: boolean = false

	rules: any = {
		email: 'required',
		password: 'required'
	}
	
	@auth.Action
	public login!: (payload: {password: string, email: string}) => any

	public doLogin () {
		(this.$refs.form as any).validate().then((res: Boolean) => {
			if (res) {
				this.submiting = true
				this.login({password: this.form.password, email: this.form.email}).then((res: any) => {
					this.submiting = false
					this.$toast.success(this.$t('AUTH.LOGIN_SUCCESS'))
					if (this.$route.query && this.$route.query.ref) {
						this.$router.replace({name: this.$route.query.ref as string})
					} else {
						this.$router.push('/mini-programs')
					}
				}).catch((err: any) => {
					this.$toast.error(this.$t('AUTH.LOGIN_FAILED'))
					this.submiting = false
				})
			}
		}) 

	}
}
